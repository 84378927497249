<!-- 旅游模版页 -->
<template>
  <div class="frame" @click="closeFixed">
    <fixed-box
      :show="isOpen"
      :userInfo="nowUser"
      :left="mouseX"
      :top="mouseY"
      @clickPop="clickPop"
    ></fixed-box>
    <back-top />
    <van-popup v-model="qaShow">
      <div class="qa-popup">
        <div class="item">
          <div class="type">问</div>
          <div class="desc">{{ nowQa.question }}</div>
        </div>
        <div class="item answer">
          <div class="type">答</div>
          <div class="desc" v-html="nowQa.answer"></div>
        </div>
      </div>
    </van-popup>
    <div id="banners">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in nowData.banners" :key="item">
          <img :src="item" alt="横幅" class="banner" />
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <div class="contain">
      <div class="question-box">
        <div class="in">
          <div class="left">
            <div class="title">{{ nowData.question.title }}</div>
            <div class="conditions">
              <div
                class="item"
                v-for="item in nowData.question.conditions"
                :key="item.content"
              >
                <img
                  src="../assets/time.png"
                  class="icon"
                  v-if="item.type == 'time'"
                />
                <img
                  src="../assets/calendar.png"
                  class="icon"
                  v-else-if="item.type == 'calendar'"
                />
                <img
                  src="../assets/cost.png"
                  class="icon"
                  v-else-if="item.type == 'cost'"
                />
                <img src="../assets/member.png" class="icon" v-else />
                <span>{{ item.content }}</span>
              </div>
            </div>
            <div class="desc">{{ nowData.question.detail }}</div>
            <div class="tip">
              被浏览 {{ nowData.question.viewed.toLocaleString("en-US") }}次
            </div>
            <div class="btns">
              <van-button
                type="info"
                icon="guide-o"
                color="#e6322e"
                plain
                size="small"
                @click="notice"
                v-if="!nowData.question.isNotice"
                >关注 {{ nowData.question.noticer }}</van-button
              >
              <van-button
                type="info"
                icon="guide-o"
                color="#e6322e"
                size="small"
                @click="notice"
                v-else
                >已关注 {{ nowData.question.noticer + 1 }}</van-button
              >
              <van-button
                type="info"
                icon="edit"
                color="#e6322e"
                size="small"
                style="margin-left: 10px"
                @click="scrollToAnswer"
                >写回答</van-button
              >
            </div>
          </div>
          <div class="numbers">
            <div class="number">
              <div class="type">关注者</div>
              <div class="num">
                {{ handleNoticer }}
              </div>
            </div>
            <div class="number">
              <div class="type">被浏览</div>
              <div class="num">
                {{ nowData.question.viewed.toLocaleString("en-US") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="answer-box">
          <div
            class="answer-item"
            v-for="item in nowData.answers"
            :key="item.name"
          >
            <div class="user" @click="choseUser(item, $event)">
              <img :src="item.avatar" :alt="item.name" class="avatar" />
              <div class="info">
                <div class="name">{{ item.name }}</div>
                <div class="level">{{ item.level }}</div>
              </div>
              <img
                src="../assets/certification.png"
                alt="资深认证"
                class="isSenior"
                v-if="item.isSenior"
              />
            </div>
            <div
              :class="{ rich: true, textarea: item.isTrue }"
              v-html="item.detail"
            ></div>
            <div class="time">编辑于 {{ handleTime(item.time) }}</div>
            <div class="btn-menus">
              <van-button
                type="info"
                icon="guide-o"
                plain
                color="#e6322e"
                size="small"
                v-if="!item.isSupport"
                @click="supportAnswer(item)"
                >支持 {{ item.supported }}</van-button
              >
              <van-button
                type="info"
                icon="guide-o"
                size="small"
                color="#e6322e"
                v-else
                @click="supportAnswer(item)"
                >已支持 {{ item.supported + 1 }}</van-button
              >
              <div class="btn">
                <van-icon name="chat-o" />
                <span>{{ item.comments.length }} 条评论</span>
              </div>
            </div>
            <div class="comment-wrap">
              <div class="comments">
                <div
                  class="comment"
                  v-for="comment in item.comments"
                  :key="comment.name"
                >
                  <div class="level-one">
                    <img
                      :src="comment.avatar"
                      :alt="comment.name"
                      class="comment-avatar"
                      @click="choseUser(comment, $event)"
                    />
                    <div class="comment-right">
                      <div
                        class="commenter"
                        @click="choseUser(comment, $event)"
                      >
                        <span>{{ comment.name }}</span>
                        <span class="level">{{ comment.level }}</span>
                      </div>
                      <div
                        :class="['rich', comment.isTrue ? 'textarea' : '']"
                        v-html="comment.detail"
                      ></div>
                      <div class="time">
                        <span>评论于 {{ handleTime(comment.time) }}</span>
                        <span class="reply" @click="replyComment(comment)"
                          >回复</span
                        >
                      </div>
                      <div class="comment-input" v-show="comment.replyShow">
                        <div class="ipt">
                          <van-field
                            v-model="replyInfo.detail"
                            :rows="isMobile ? 1 : 2"
                            autosize
                            type="textarea"
                            :placeholder="'回复 ' + replyInfo.name"
                          />
                        </div>
                        <div class="submit">
                          <van-button
                            type="info"
                            color="#e6322e"
                            block
                            @click="submitReply(comment)"
                            >回复</van-button
                          >
                        </div>
                      </div>
                      <div class="replies">
                        <div
                          class="level-one"
                          v-for="(reply, replyIdx) in comment.replies"
                          :key="reply.detail"
                        >
                          <img
                            :src="reply.avatar"
                            :alt="reply.name"
                            class="comment-avatar"
                            @click="choseUser(reply, $event)"
                          />
                          <div class="comment-right">
                            <div class="reply-comment">
                              <div
                                class="commenter"
                                @click="choseUser(reply, $event)"
                              >
                                <span>{{ reply.name }}</span>
                                <span class="level">{{ reply.level }}</span>
                              </div>
                              <div class="reply">
                                <span class="center">回复</span>
                                <span>{{
                                  reply.replyDto && reply.replyDto.name
                                }}</span>
                              </div>
                            </div>
                            <div
                              :class="['rich', reply.isTrue ? 'textarea' : '']"
                              v-html="reply.detail"
                            ></div>
                            <div class="time">
                              <span>评论于 {{ handleTime(reply.time) }}</span>
                              <span class="reply" @click="replyComment(reply)"
                                >回复</span
                              >
                            </div>
                            <div class="comment-input" v-show="reply.replyShow">
                              <div class="ipt">
                                <van-field
                                  v-model="replyInfo.detail"
                                  :rows="isMobile ? 1 : 2"
                                  autosize
                                  type="textarea"
                                  :placeholder="'回复 ' + replyInfo.name"
                                />
                              </div>
                              <div class="submit">
                                <van-button
                                  type="info"
                                  color="#e6322e"
                                  block
                                  @click="
                                    submitLevelTwoReply(
                                      comment,
                                      replyIdx,
                                      reply
                                    )
                                  "
                                  >回复</van-button
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="comment-input level-one-ipt">
                <div class="ipt">
                  <van-field
                    v-model="item.myComment"
                    :rows="isMobile ? 1 : 4"
                    autosize
                    type="textarea"
                    placeholder="写下你的评论…"
                  />
                </div>
                <div class="submit">
                  <van-button
                    type="info"
                    color="#e6322e"
                    block
                    @click="submitComment(item, 1)"
                    >发布</van-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="my-answer">
            <img
              src="https://www.universe-trip.com/file/my-avatar.jpg"
              class="default"
            />
            <div class="my-box">
              <div class="label">添加回答</div>
              <div class="ipt">
                <van-field
                  v-model="content"
                  :rows="isMobile ? 1 : 6"
                  autosize
                  type="textarea"
                  placeholder="写下你的回答"
                />
              </div>
              <van-button color="#e6322e" @click="submitAnswer"
                >提 交</van-button
              >
            </div>
          </div>
        </div>
        <!-- <div class="fixed">
          <div class="qa-box">
            <div class="qa-title">网友问答</div>
            <div class="qa-list">
              <div
                class="qa-item"
                v-for="(item, idx) in nowData.qaList"
                :key="idx"
                @click="openQaDetail(item)"
              >
                <div class="type">问</div>
                <div class="item-right">
                  <div class="item-title">{{ item.question }}</div>
                  <div class="item-desc" v-html="item.answer"></div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <footer class="footer">
      <div class="in">
        成都寰宇纵横国际旅行社有限公司
        <a href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow"
          >蜀ICP备2023016641号</a
        >
      </div>
    </footer>
    <div class="fix-bottom">
      <div class="btn btn1" @click="openContact" v-if="nowData.ifContact">
        <van-button type="primary" block>立即在线咨询</van-button>
      </div>
      <div
        class="btn btn1 weixin"
        v-if="nowData.ifWechat"
        :data-clipboard-text="nowData.wechat"
        data-agl-cvt="35"
        @click="openWechat"
      >
        <van-button type="primary" block
          >添加{{ nowData.character }}微信</van-button
        >
      </div>
      <div class="btn" v-if="nowData.ifCall">
        <van-button type="primary" plain block @click="actionShow = true"
          >拨打{{ nowData.character }}电话</van-button
        >
      </div>
    </div>
    <van-action-sheet
      v-model="actionShow"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @cancel="onCancel"
      @select="actionSelect"
    />
  </div>
</template>
<script>
import { Toast, Dialog } from "vant";
import Clipboard from "clipboard";
import fixedBox from "../components/fixedBox.vue";
import BackTop from "../components/backTop.vue";
export default {
  components: { fixedBox, BackTop },
  data() {
    return {
      nowData: {
        banners: [],
        question: {
          viewed: "",
        },
        answers: [],
        qaList: [],
      }, // 当前页面数据
      nowUser: {}, // 当前选择用户
      mouseX: 0, // 点击时的鼠标横坐标
      mouseY: 0, // 点击时的鼠标纵坐标
      isOpen: false, // 用户悬浮框是否展示
      ableOpen: false, // 判断用户当前点击的元素是否应该展示悬浮框
      noticeArr: [], // 我已关注的问题列表
      content: "", // 我的回答
      qaShow: false, // qa详情弹出框
      nowQa: {},
      isMobile: false,
      actionShow: false,
      actions: [],
      myReplyComment: "", // 用于回复的消息内容
      userInfo: {
        name: "我",
        level: "LV1",
        avatar: "https://www.universe-trip.com/file/my-avatar.jpg",
      }, // 用户评论真实数据
      replyInfo: {
        detail: "",
        name: "",
        level: "",
        avatar: "",
      }, // 用于回复的消息内容、被回复人的信息
    };
  },
  computed: {
    // 关注者数量处理
    handleNoticer() {
      const noticer = this.nowData.question.isNotice
        ? this.nowData.question.noticer + 1
        : this.nowData.question.noticer;
      return noticer && noticer.toLocaleString("en-US");
    },
    // 时间判断处理
    handleTime() {
      return (time) => {
        let date = new Date(time).getTime();
        let nowDate = new Date().getTime();
        const timeOld = nowDate - date; // 总时间差
        const daysOld = Math.floor(timeOld / (24 * 60 * 60 * 1000)); // 总天数
        if (daysOld < 1) {
          return "今天";
        }
        if (daysOld <= 7) {
          return daysOld + "天前";
        }
        return time;
      };
    },
  },
  created() {
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    this.isMobile = !!flag;
    this.importData(); // 导入数据
  },
  mounted() {
    const type = this.$route.path.split("/")[1];
    if (type == "xinjiang") {
      // 此页面需要巨量广告外接js
      this.getJuLiangJs();
    }
    if (type == "beijiang") {
      // 此页面需要腾讯广告外接js
      this.getTengXunJs(
        window,
        document,
        "script",
        "//qzonestyle.gtimg.cn/qzone/biz/gdt/dmp/user-action/gdtevent.min.js"
      );
    }
    if (type == "chuanzang") {
      this.getBaiduJs();
    }

    // 复制插件
    let clipboard = new Clipboard(".btn1");
    clipboard.on("success", () => {
      // 复制成功
      Dialog.confirm({
        title: "微信号复制成功!",
        message: "快前往微信添加好友吧",
        confirmButtonText: "去添加",
      }).then(() => {
        window.location.href = "weixin://dl/addfriend";
      });
    });
  },
  methods: {
    async importData() {
      const type = this.$route.path.split("/")[1];
      let res = await import(`../data/${type}.js`);
      if (!res.default) return;
      this.formatIptShow(res.default);
      this.nowData = res.default;
      this.actions = [{ name: this.nowData.phoneNum }];
      if (!this.nowData) {
        // 若路径有错不进入此页面
        this.$router.replace("/404");
      }
      // 获取已关注问题列表-判断是否已关注
      if (localStorage.getItem("noticeArr")) {
        this.noticeArr = JSON.parse(localStorage.getItem("noticeArr")) || [];
        if (this.noticeArr.some((ele) => ele == this.nowData.type)) {
          // 若当前问题已关注则直接修改状态
          this.nowData.question.isNotice = true;
        }
      }
      setTimeout(() => {
        this.initSwiper();
      }, 10);
    },
    // 初始化每个评论的回复框的展示状态
    formatIptShow(data) {
      data.answers.forEach((ele) => {
        // 一级评论循环
        ele.comments.forEach((comment) => {
          // 二级评论循环
          comment.replyShow = false;
          if (comment.replies) {
            comment.replies.forEach((reply) => {
              // 三极评论循环
              reply.replyShow = false;
            });
          }
        });
      });
    },
    // 百度外接js
    getBaiduJs() {
      var _hmt = _hmt || [];
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?648f1e98c685f873b5fabf38bd2a9baf";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    },
    // 巨量外接js
    getJuLiangJs() {
      let r = window;
      let d = document;
      let s = "script";
      r._tt_config = true;
      var _baq = (r._baq = r._baq || []);
      window._baq = _baq;
      _baq.methods = ["track", "off", "on"];
      _baq.factory = function (method) {
        return function () {
          var args = Array.prototype.slice.call(arguments);
          args.unshift(method);
          _baq.push(args);
          return _baq;
        };
      };
      for (var i = 0; i < _baq.methods.length; i++) {
        var key = _baq.methods[i];
        _baq[key] = _baq.factory(key);
      }
      _baq.load = function () {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        js = d.createElement(s);
        js.src = "https://analytics.oceanengine.com/api/v2/js/sdk";
        fjs.parentNode.insertBefore(js, fjs);
      };
      _baq.load();
      if (_baq.invoked) {
        return;
      }
      _baq.invoked = true;
      _baq.track("pageview");
      _baq.on("insight");
    },
    // 腾讯外接js
    getTengXunJs(g, d, t, e, v, n, s) {
      if (g.gdt) return;
      v = g.gdt = function () {
        v.tk ? v.tk.apply(v, arguments) : v.queue.push(arguments);
      };
      v.sv = "1.0";
      v.bt = 0;
      v.queue = [];
      n = d.createElement(t);
      n.async = !0;
      n.src = e;
      s = d.getElementsByTagName(t)[0];
      s.parentNode.insertBefore(n, s);

      g.gdt("init", "1201649613");
      g.gdt("track", "PAGE_VIEW");
    },
    initSwiper() {
      new window.Swiper("#banners", {
        loop: true,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: ".swiper-pagination",
        },
      });
    },
    skipToComment(idx) {
      const comments = document.getElementsByClassName("level-one-ipt");
      window.scrollTo({
        top: comments[idx].offsetTop - 40,
        behavior: "smooth",
      });
    },
    closeFixed() {
      if (!this.ableOpen) {
        this.isOpen = false;
      }
      this.ableOpen = false;
    },
    // 悬浮框内部点击时不会隐藏悬浮框
    clickPop() {
      this.ableOpen = true;
    },
    choseUser(item, e) {
      this.ableOpen = true;
      this.isOpen = true;
      this.mouseX = e.clientX + 10;
      this.mouseY = e.pageY + 10;
      this.nowUser = item;
    },
    // 关注/取消关注 问题
    notice() {
      if (this.nowData.question.isNotice) {
        // 取消 删除已关注数据
        let idx = this.noticeArr.find((ele) => ele == this.nowData.type);
        this.noticeArr.splice(idx, 1);
        Toast("取消关注成功");
      } else {
        // 关注 添加已关注数据
        this.noticeArr.push(this.nowData.type);
        Toast.success("关注成功");
      }
      localStorage.setItem("noticeArr", JSON.stringify(this.noticeArr));
      if (this.noticeArr.length == 0) {
        // 若无关注问题则直接清空缓存数据
        localStorage.removeItem("noticeArr");
      }
      this.nowData.question.isNotice = !this.nowData.question.isNotice;
    },
    // 是否支持一级评论
    supportAnswer(item) {
      if (item.isSupport) {
        Toast("取消成功");
      } else {
        Toast.success("支持成功");
      }
      item.isSupport = !item.isSupport;
    },
    // 提交对于一级评论的二级评论
    submitComment(item) {
      if (!item.myComment) {
        Toast("评论不能为空!");
        return;
      }
      item.comments.push({
        ...this.userInfo,
        isTrue: true, // 用户真实评论内容，需要格式处理
        time: new Date().toLocaleString().split(" ")[0],
        detail: item.myComment,
      });
      item.myComment = "";
      Toast.success("发表成功!");
    },
    // 前往填写一级评论
    scrollToAnswer() {
      let myAnswer = document.getElementsByClassName("my-box")[0];
      window.scrollTo({
        top: myAnswer.offsetTop - 30,
        behavior: "smooth",
      });
    },
    // 提交一级评论
    submitAnswer() {
      if (!this.content) {
        Toast("评论不能为空!");
        return;
      }
      this.nowData.answers.push({
        ...this.userInfo,
        isTrue: true, // 用户真实评论内容，需要格式处理
        time: new Date().toLocaleString().split(" ")[0],
        detail: this.content,
        comments: [],
        myComment: ``,
        supported: 0,
        isSupport: false,
      });
      this.content = "";
      Toast.success("发表成功!");
    },
    // 回复评论
    replyComment(comment) {
      this.replyInfo = {
        name: comment.name,
        level: comment.level,
        avatar: comment.avatar,
        detail: "",
      };
      this.formatIptShow(this.nowData);
      comment.replyShow = true;
      this.$forceUpdate();
    },
    // 提交回复
    submitReply(comment) {
      const time = new Date().toLocaleDateString().replace(/\//g, "-");
      const data = {
        ...this.userInfo,
        time,
        detail: this.replyInfo.detail,
        replyDto: { ...this.replyInfo },
      };

      if (comment.replies) {
        comment.replies.push(data);
      } else {
        comment.replies = [data];
      }
      comment.replyShow = false;
      Object.assign(this.$data.replyInfo, this.$options.data().replyInfo);
      Toast.success("评论成功!");
    },
    // 提交二级回复
    submitLevelTwoReply(comment, replyIdx, reply) {
      const time = new Date().toLocaleDateString().replace(/\//g, "-");
      const data = {
        ...this.userInfo,
        time,
        detail: this.replyInfo.detail,
        replyDto: { ...this.replyInfo },
      };
      comment.replies.splice(replyIdx + 1, 0, data);
      reply.replyShow = false;
      Object.assign(this.$data.replyInfo, this.$options.data().replyInfo);
      Toast.success("评论成功!");
    },
    // 查看qa详情
    openQaDetail(item) {
      this.nowQa = item;
      this.qaShow = true;
    },
    onCancel() {
      this.actionShow = false;
    },
    actionSelect(e) {
      if (e.name) {
        window.location.href = "tel:" + e.name;
      }
    },
    // 巨量广告专属在线咨询事件
    openContact() {
      window._baq.track("consult_effective");
    },
    openWechat() {
      const type = this.$route.path.split("/")[1];
      if (type == "chuanzang") {
        // 百度广告自定义线索转化事件
        const weixinDom = document.getElementsByClassName("weixin")[0];
        console.log(weixinDom.getAttribute("data-agl-cvt"));
      } else {
        // 腾讯广告专属事件
        window.gdt("track_custom", "ADD_WECHAT");
      }
    },
  },
};
</script>

<style lang='scss' scoped>
$--theme-color: #e6322e;
#banners {
  position: relative;
  overflow: hidden;

  --swiper-pagination-color: #e6322e;
}
.qa-popup {
  width: 600px;
  padding: 30px 20px;

  .item {
    display: flex;

    .type {
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      border-radius: 4px;
      font-size: 14px;
      flex-shrink: 0;
      color: #fff;
      background-color: $--theme-color;
      margin-right: 10px;
    }
    .desc {
      font-size: 22px;
      font-weight: bold;
      color: #000;
    }
  }
  .answer {
    margin-top: 20px;

    .type {
      background-color: #4e6ef2;
    }
  }
}
.rich {
  line-height: 1.5;

  ::v-deep {
    img {
      max-width: 100%;
      height: auto;
      margin-top: 5px;
    }
    a:hover {
      text-decoration: underline;
      cursor: pointer;
      user-select: none;
    }
  }
}
.frame {
  background-color: #f7f8fa;
}
.banner {
  width: 100%;
  height: 560px;
  object-fit: cover;
  display: block;
}
.contain {
  width: 100%;

  .question-box {
    padding: 16px 20px;
    box-shadow: 0 0 10px #eee;
    background-color: #fff;

    .in {
      width: 1000px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }

    .title {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.22;
      margin-bottom: 10px;
      word-break: break-all;
    }
    .tip {
      color: #8590a6;
      font-size: 14px;
      margin-bottom: 8px;
      display: none;
    }
    .conditions {
      margin-bottom: 10px;
      display: flex;
      // justify-content: space-between;

      .item {
        width: 25%;
        display: flex;
        align-items: center;
        font-size: 14px;
        white-space: nowrap;

        .icon {
          height: 20px;
        }

        span {
          margin-left: 5px;
        }
      }
    }
    .desc {
      color: #333;
      line-height: 1.6;
      margin-bottom: 20px;
    }
    .btns {
      display: flex;
      align-items: center;

      .btn {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #8590a6;
        cursor: pointer;
        margin-left: 20px;

        &:hover {
          color: #76839b;
        }

        span {
          margin-left: 5px;
        }
      }
    }

    .numbers {
      width: 300px;
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      .number {
        padding: 0 8px;
        line-height: 1.6;
        text-align: center;

        & + .number {
          border-left: 1px solid #eee;
        }

        .type {
          color: #8590a6;
          font-size: 14px;
        }
        .num {
          font-weight: bold;
          color: #121212;
          font-size: 18px;
        }
      }
    }
  }

  .content {
    width: 1000px;
    margin: 20px auto 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .answer-box {
      // width: 694px;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 0 10px #eee;
      padding: 20px;
      box-sizing: border-box;

      .answer-item {
        position: relative;

        & + .answer-item {
          margin-top: 20px;
          padding-top: 20px;
          border-top: 1px solid #eee;
        }
        .user {
          width: fit-content;
          padding-right: 20px;
          display: flex;
          align-items: center;
          cursor: pointer;

          .avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            object-fit: cover;
          }
          .info {
            color: #1f1f1f;
            font-size: 14px;
            margin-left: 20px;

            .name {
              font-weight: bold;
            }

            .level {
              margin-top: 3px;
            }
          }
          .isSenior {
            width: 150px;
            position: absolute;
            right: 0;
            top: -25px;
          }
        }
        .rich {
          margin: 20px 0 10px;
        }
        .textarea {
          white-space: pre-wrap;
        }
        .time {
          margin-bottom: 10px;
          color: #8590a6;
          font-size: 14px;
        }
        .btn-menus {
          display: flex;
          align-items: center;

          .btn {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #8590a6;
            cursor: pointer;
            margin-left: 20px;

            &:hover {
              color: #76839b;
            }
            .van-icon {
              font-size: 18px;
            }

            span {
              margin-left: 5px;
            }
          }
        }
        .comment-wrap {
          border: 1px solid #ebebeb;
          border-radius: 4px;
          padding: 10px 0;
          margin-top: 15px;
        }
        .comments {
          .comment {
            padding-bottom: 10px;

            .level-one {
              padding: 0 10px;
              display: flex;
              font-size: 15px;
              line-height: 1.8;

              .comment-avatar {
                width: 32px;
                height: 32px;
                object-fit: cover;
                border-radius: 50%;
                margin-right: 10px;
                cursor: pointer;
              }
              .comment-right {
                flex-grow: 1;

                .commenter {
                  width: fit-content;
                  padding-right: 20px;
                  color: #e6322e;
                  cursor: pointer;

                  span:hover {
                    text-decoration: underline;
                  }
                }

                .level {
                  color: #ef523d;
                  margin-left: 6px;
                }
                .rich {
                  margin-top: 5px;
                }
                .textarea {
                  white-space: pre-wrap;
                }
                .time {
                  font-size: 12px;
                  margin-top: 5px;
                  line-height: 14px;
                  color: #999;

                  .reply {
                    margin-left: 20px;
                    user-select: none;
                    color: #333;

                    &:hover {
                      cursor: pointer;
                      color: $--theme-color;
                    }
                  }
                }

                .replies {
                  .comment-right {
                    flex-grow: 1;

                    .reply-comment {
                      display: flex;

                      .reply {
                        font-size: 14px;
                        color: $--theme-color;

                        .center {
                          margin-right: 20px;
                          color: #333;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .comment-input {
          margin: 15px;
          display: flex;
          justify-content: space-between;

          .ipt {
            width: 85%;
            // padding: 10px 15px;
            box-sizing: border-box;
            font-size: 14px;
            // border: 1px solid #c6c8ce;
            border-radius: 4px;
            box-shadow: 0 0 4px rgba(182, 195, 214, 0.6), 0 1px 1px #fff;
            resize: none;
          }
          .submit {
            width: 13%;
          }
        }
      }
      .my-answer {
        margin-top: 30px;
        display: flex;

        .default {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
        }
        .my-box {
          flex-grow: 1;
          margin-left: 15px;
          text-align: right;

          .label {
            font-size: 16px;
            color: #e6322e;
            margin-bottom: 10px;
            text-align: left;
          }
          .ipt {
            width: 100%;
            border: 1px solid #e5e5e5;
            margin-bottom: 10px;
            // padding: 5px;
            box-sizing: border-box;
            resize: none;
            outline: 0;
            font-size: 14px;
          }
        }
      }
    }
    .fixed {
      width: 300px;
      flex-shrink: 0;
      padding-left: 20px;
      box-sizing: border-box;
      position: sticky;
      top: 5px;

      .qa-box {
        border-radius: 2px;
        background-color: #fff;

        .qa-title {
          color: $--theme-color;
          font-size: 20px;
          text-align: center;
          font-weight: bold;
          padding: 10px 0;
        }
        .qa-list {
          padding: 0 14px;

          .qa-item {
            padding-bottom: 15px;
            display: flex;
            align-items: flex-start;

            &:hover {
              cursor: pointer;

              .item-title {
                text-decoration: underline;
              }
            }

            .type {
              width: 18px;
              height: 18px;
              text-align: center;
              line-height: 18px;
              border-radius: 4px;
              color: #fff;
              background-color: $--theme-color;
              font-size: 12px;
              margin-right: 10px;
              flex-shrink: 0;
            }
            .item-right {
              flex-grow: 1;

              div {
                -webkit-line-clamp: 2;
                word-break: break-all;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              .item-title {
                font-size: 17px;
                color: #000;
                font-weight: bold;
              }
              .item-desc {
                margin-top: 8px;
                color: rgba(102, 102, 102, 0.7);
                font-size: 14px;

                ::v-deep span {
                  color: rgba(102, 102, 102, 0.9) !important;
                  font-size: 14px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
.footer {
  height: 60px;
  background-color: #3c3c3c;
  color: #98a3ac;
  line-height: 22px;
  font-size: 12px;
  position: relative;

  .in {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    a {
      color: #c2c2c2;
    }
  }
}
.fix-bottom {
  width: 100%;
  padding: 8px 10px;
  box-sizing: border-box;
  box-shadow: 0 0 10px #ddd;
  background-color: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  display: none;

  .btn {
    flex: 1;

    & + .btn {
      margin-left: 10px;
    }
  }
}

@media (max-width: 960px) {
  .banner {
    height: 30vh;
    object-fit: cover;
  }
  .contain {
    .question-box {
      padding: 16px 10px;

      .in {
        width: 100%;

        .left {
          .tip {
            display: block;
          }
          .title {
            font-size: 20px;
            text-align: justify;
          }
          .conditions {
            flex-wrap: wrap;
            background-color: #f7f7f7;
            padding: 10px;
            border-radius: 5px;

            .item {
              width: 49%;
              font-size: 13px;

              &:nth-child(1),
              &:nth-child(2) {
                margin-bottom: 10px;
              }
              &:nth-child(2n -1) {
                margin-right: 5px;
              }

              .icon {
                height: 15px;
              }
            }
          }
          .desc {
            font-size: 15px;
            margin-bottom: 10px;
            text-align: justify;
          }
          .btns {
            width: 100%;

            .van-button {
              width: 50%;
            }
          }
        }
        .numbers {
          display: none;
        }
      }
    }
    .content {
      width: 100%;
      flex-direction: column;
      margin-top: 10px;
      margin-bottom: 20px;

      .answer-box {
        width: 100%;
        padding: 16px 10px;

        .answer-item {
          .comment-right {
            .van-button--normal {
              padding: 0 5px;
            }
            .comment-input {
              margin: 10px 0;

              .submit {
                width: 18%;
              }
            }
          }
          .comment-input .submit {
            width: 25%;
            margin-left: 10px;
          }
          .user .isSenior {
            width: 100px;
          }
          .comments .comment .level-one {
            padding-left: 0;
          }
          .reply-comment {
            flex-direction: column;
            border-bottom: 1px solid #ddd;
          }
        }
      }
      .fixed {
        width: 100%;
        padding-left: 0;
        margin-top: 10px;
      }
    }
  }
  .footer {
    text-align: center;
    margin-bottom: 60px;

    .in {
      white-space: nowrap;

      a {
        display: block;
      }
    }
  }
  .qa-popup {
    width: 70vw;

    .item .desc {
      font-size: 20px;
    }
  }
  .qa-box {
    .qa-title {
      background-image: linear-gradient(
        rgba(230, 50, 46, 0.16),
        rgb(255, 255, 255)
      );
      position: relative;

      &::after {
        content: "";
        width: 70px;
        height: 50px;
        background-image: url("../assets/crown.svg");
        background-size: cover;
        position: absolute;
        top: 50%;
        right: 10%;
        transform: translateY(-50%);
      }
    }
  }
  .fix-bottom {
    display: flex;
  }
}
</style>