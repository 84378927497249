import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import {
  Button,
  Icon,
  Popup,
  Swipe,
  SwipeItem,
  Field,
  ActionSheet,
} from "vant";
Vue.use(Button);
Vue.use(Icon);
Vue.use(Popup);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Field);
Vue.use(ActionSheet);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
