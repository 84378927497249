<!-- 用户悬浮框 -->
<template>
  <div
    class="popUser"
    :style="{
      left: left + 'px',
      top: top + 'px',
      display: show ? 'flex' : 'none',
    }"
    @click="$emit('clickPop')"
  >
    <img :src="userInfo.avatar" :alt="userInfo.name" class="avatar" />
    <div class="right">
      <div class="name">{{ userInfo.name }}</div>
      <div class="level">等级：{{ userInfo.level }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    userInfo: {
      type: Object,
      default: () => {},
    },
    left: {
      type: Number,
      default: 0,
    },
    top: {
      type: Number,
      default: 0,
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.popUser {
  width: 280px;
  padding: 10px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 0 10px #eee;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;

  .avatar {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
  }
  .right {
    margin-left: 10px;

    .name {
      font-weight: bold;
      margin-bottom: 5px;
      font-size: 14px;
      color: #333;
    }
    .level {
      font-size: 13px;
      color: #666;
    }
  }
}
</style>