import Vue from "vue";
import Router from "vue-router";
import template from "../views/template.vue";
import chuanzangTemp from "../views/chuanzangTemp.vue";

Vue.use(Router);

const router = new Router({
  // mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      redirect: "/beijiang",
    },
    {
      path: "/beijiang",
      name: "北疆",
      component: template,
    },
    {
      path: "/xinjiang",
      name: "新疆",
      component: template,
    },
    {
      path: "/chuanzang",
      name: "川藏",
      component: chuanzangTemp,
    },
  ],
});

export default router;
