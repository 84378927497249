<!-- 返回顶部组件 -->
<template>
  <div class="backtop" v-if="show" @click="skipTop"></div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  created() {
    window.addEventListener("scroll", () => {
      let top = document.documentElement.scrollTop;
      this.show = top > 500;
    });
  },
  mounted() {},
  methods: {
    skipTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style lang='scss' scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.backtop {
  width: 40px;
  height: 40px;
  position: fixed;
  right: 40px;
  bottom: 70px;
  background-color: #fff;
  border-radius: 50%;
  color: #e6322e;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  z-index: 5;
  animation: fadeIn 0.2s linear;

  &::after {
    content: "\25B2";
  }
}
</style>